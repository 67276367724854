body {margin: 0;font-family: 'Lato', sans-serif;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;background-color: #f9f9f9;}

  
/* This code is for HomePage */
.listDesign{background-color: none;box-shadow: none;font-family: 'Lato',sans-serif;font-weight: 600;font-size: 16px;}
.NavLink{color: black;padding-left: 0px;}
.NavLink:hover{color: black;} .NavLink:focus{color: black;}
.listIcon{height: 15px;width: 15px;float: right;}
.desginClock{font-size:100px;opacity: 0.7;color: black;}.textdesgin{margin-top: 200px;}
.formDesgin{font-size: 18px; padding:10px;font-weight: 600;}.fromTextdesgin{color: #48c9b0;font-size: 18px;}
.fromtextDesgin2{opacity: 0.6;font-size: 20px;margin-bottom: 5px;}
.btnDesgin{opacity: 0.8;padding: 8px 40px; margin: 10px;border-radius: 5px;margin-bottom: 20px;}
.btnCustDesgin{border-color: #48c9b0;color: #48c9b0;}
.btnCustDesgin:hover{background-color: none;}

.ModalHomePage{background-color: #48c9b0;border-radius: inherit;text-align: center;padding: 20px;width: 300px;border: none;}
.ModalIconHome{height: 40px;width: 40px;}
.SpanTextDesginHome{color: #ffffff;font-size: 18px;}
/* This css id for EmployeeTimeline */
.HeaderText{font-size: 40px;}
.CustCanvasDesgin{width: 100%;height: 600px;}
/* This is for EmployeeDetails for Modal popup */

.modal-content {border-radius: 20px}
.desginModalHead{background-color: #48c9b0;padding: 15px;border-radius: 15px;justify-content: center;}
.desginModalTitle{color: #f9f9f9;font-weight: 600;border-radius: 5px;}
.btnModalFoot{padding: 0px;margin: 0px;background-color: #f9f9f9; border:none; color: red;padding-right: 8px;}
.btnModalFoot:hover{background-color: transparent;color: red;}
.btnModalFoot:focus{background-color: transparent; color: red;border-color: #f9f9f9;border-style: none;border-width: 0px;border-width: 0px; outline: none;box-shadow: none;}
.CustHrefText{text-decoration: none;}.CustHrefText:hover{color: #0d6efd;}
.CustTextbox:focus{border: none;}
.CustomDesginCheck{border: none !important; border-color: green;}
.CustomDesginCheck:focus{border: none !important; border-color: green;}
/* This is code is for settings page  */
.desginRow{padding: 0px;}
.nav-link.CustNavDesgin{border-bottom: solid !important;border-width: 1px !important;width: 100%;border-radius: 0px;opacity: 0.7;}
.CustNavDesgin.active{background-color: #d6f0f7 !important; color: #000 !important;}
.CustNavDesgin {color: #000;border-style: solid;padding: 18px;display: inline-block;font-size: 18px;font-weight: 600;}
.CustNavDesgin:hover{color: #000 !important;}


/* This is for setting general page  */
.custRow{padding: 0px;}
.BasicBox{height: 640px; background-color: #d9dfe9f5;}
.DesginText{margin-top: 10px;background-color: #ffffff; padding: 18px; border-radius: 10px;}
.custChange{opacity: 0.5;}
.additionChanges{color: red;text-align: center;margin-top: 30px;}
.AdminPinChange{opacity: 0.7;}
.DesginTextSwitch{margin-top: 30px;background-color: #ffffff; padding: 18px; border-radius: 10px;font-family: sans-serif;font-size: 18px;}
/* This is for settingtags page  */
.AddingTagsChange{text-align: center;color: red;opacity: 0.6;text-decoration: none;cursor: pointer;}
.BasicBoxTwo{height: 640px;background-color: #d8e4e7;margin: 0px;padding: 0px;}
.DesginTextTags{margin: 10px;;margin-top: 10px;background-color: #ffffff; padding: 15px; border-radius: 10px;}
.commomDesgin{border-radius: 10px;padding: 10px;}
.custDesginInputGroup{background-color: #ffffff;border: none;}
.CustDesginTextBox{border: none;}
.CustDesginTextBox:focus{box-shadow: none;}
.CustFormDesgin{ margin-top: 20px;}
.CustListDesgin{margin-top: 10px;}
.CustlistIcon{height: 15px;width: 15px;float: right;}

/* This code is for automatic breaks  */
.BasicBoxThree{height: 640px;background-color: #ffffff;padding: 0px;margin: 0px;}
.EspicallyRowDesgin{padding: 0px;margin: 0px;}
/* Sliding Bar  */
.CustSlideDesgin{color: #ffffff;background-color: red;}
.CustDesginButtonEdit{background-color: #d8e4e7;color: red;border:none;margin-top: 540px;position:absolute;align-items: center;width: max-content;margin-left:10px;width: 62%;}
.CustDesginButtonEdit:hover{background-color: #d8e4e7;color: red;}
.CustDesginButtonEdit:focus{background-color: #d8e4e7;}
.btn-check:active+.btn-primary:focus{box-shadow: 0 0 0  rgb(0 0 0 );}

/* This css is for Setting Support  */
.BasicBoxFour{height: 640px;background-color: #d8e4e7;}
.CustListBoxSupport{border-radius: 10px; margin-top: 20px;padding: 10px;text-align: center;}
.ListofSupport{color: red;}

/* This is code is for Setting terms  */
.SpanTextSameDesgin{color: #48c9b0;font-weight: 600;font-size: 25px;}
.SpanTextDiff{color: #48c9b0;font-weight: 600;margin-left: 20px;}

/* This code is for SettingWhatNew */
.baiscBoxSix{padding: 0px;background-color: #ffffff;}
.SearchIconDesgin{margin: 10px;}
.BoxSimple{width: 800px; align-items: center;margin-left: auto;margin-right: auto;}
.SpanTextFi{font-size: 32px;font-weight: 600;}
.SpanTextSe{font-size: 25px;font-weight: 600;}
.SpanTextThir{list-style-type: circle;font-weight: 600;}
.SpanTextFour{font-size: 20px;font-weight: 600;}
.NavCustDesginNew{color: #ffffff;}

/* This code is for Setting CommunityFrom  */
.textCustForm{color: #000;font-weight: 600;}
.textCustFormFirst{color: black;font-weight: 700;}
.communityDesgin{padding: 0px;background-color: #ffffff;}
.NewIconDesgin{height: 20px;width: 20px;}.Normal{display: inline-block;}
.textDesgin{color: #ffffff;}
.OtherSideIcon{height: 30px; width: 30px;}
.btnDesginForm{background-color: #48c9b0 !important;height: 35px; border-radius: 10px;align-items: center;text-align: center;width: 200px;margin-top: 5px;margin-left: 10px;border:none;}
.btnDesginForm:focus{box-shadow: none}
.btn-check:active+.btn-primary:focus{box-shadow: none;}
.FloatBanner{height: 130px;padding: 30px;background-color: #ffffff;-webkit-box-shadow: 0 8px 6px -6px black;-moz-box-shadow: 0 8px 6px -6px black;box-shadow: 0 8px 6px -6px black;}
.BtnEditDesgin{background-color: #ffffff !important; color: #48c9b0 !important;border-color: #48c9b0 !important;padding: 10px;width: 100%;}
.BtnEditDesgin:focus{background-color: #ffffff;color: #48c9b0;box-shadow:none;}
.BtnEditDesgin.btn-check:active+.btn-primary:focus{box-shadow: none;}
.DesginRow{background-color: #ffffff;}
.IconsDesginSpecial{height: 25px;width: 25px;background-color: #ffffff;}
.tModalDesginFrom{background-color: #e0e0e0;border-radius: 7px;padding: 0px;}
.HeadDesginFrom{background-color: #ffffff;padding: 15px;border-radius: 8px;justify-content: center; font-size: 18px;}
.ModalText{color: red;font-weight: 500; cursor: pointer;}
.ModalTextHName{color: #000;font-weight: 600;}
.CustRowModal{background-color: #ffffff;margin-top: 40px;padding: 10px;border: 5px;}
.spanTextForm{font-weight: 600;font-size: 20px;}
.CustTextBoxForm{text-align: end;font-size: 20px;}
.CustButtonForm{background-color: #ffffff; color: red;border: none;width: 100%;margin-top: 50px;padding: 10px;font-size: 20px;margin-bottom: 400px;}
.CustButtonForm:focus{background-color: #ffffff !important;color: red;border: none;box-shadow: none !important;}
.CustButtonForm:hover{background-color: #ffffff !important;color: red !important;}

.nav-link.CustNavDesginForm{border-width: 1px !important;width: 100%;border-radius: 0px;opacity: 0.7;cursor: pointer;}
.CustNavDesginForm.active{background-color: #d6f0f7 !important; color: #000 !important;}
.CustNavDesginForm {color: #000;border-style: solid;display: inline-block;font-size: 18px;font-weight: 600;}
.CustNavDesginForm:hover{color: #000 !important;}

.modalSpanForm{font-weight: 600;}
.modalButtonTextForm{background-color: #ffffff;color: red;border: none;box-shadow: 0 0 0  rgb(0 0 0 );font-size: 18px;}
.modalButtonTextForm:hover{background-color: #ffffff !important;color: red !important;}
.modalButtonTextForm:focus{background-color: #ffffff !important;color: red;border: none;box-shadow: none !important;}
.item-native{padding-inline-start: 0px;} 
.tblCustDesginForm{border-top: #000;border-bottom: #000;}

.tbldesginform{border-bottom: none;}
/* Code for popover  */
.SidebarPopover{border-radius: 10px;font-size: 20px;padding: 10px;border: none; width: 50%;text-align: center;}
.PopupText{color: red;}
.MainModalForm{text-align: center;margin-bottom: 450px;}
.MainModalDeleteEmployee{text-align: center;}
.CustModalSecondForm{background-color: #ffffff;margin-top: 10px;padding: 10px;border: 5px;}
.MainModalFormSync{margin-bottom: 450px;}
.SecondModalDesgin{background-color: #f0f1f1;border-radius: 8px;}
.form-check-input:checked{background-color: #48c9b0 !important;border-color: #48c9b0;}
.form-check-input:focus{box-shadow: rgb(0 0 0 );border: none;}
/* This code is Modal Another Function in Cloud in settingcommunity */
.modalButtonTextFormFirst{background-color: #ffffff;color: red;border: none;box-shadow: 0 0 0  rgb(0 0 0 );font-size: 18px;}
.modalButtonTextFormFirst:hover{background-color: #ffffff !important;color: red !important;}
.modalButtonTextFormFirst:focus{background-color: #ffffff !important;color: red;border: none;box-shadow: none !important;}
.MainModalAnotherCloud{text-align: center;}
.CustModalcloudFunction{background-color: #ffffff;margin-top: 10px;border: 5px;}
.modalSpanTextFirstPage{font-weight: 600;}
.CloudBtnDesgin{border-right: solid rgb(207, 201, 201) 0.5px;}

/* THis is code is for canavas */
.signpad canvas{height: 500px;width: 100%;}

/* This code is SettingcommunityMainPage */
.HeadDesginMainPage{background-color: #ffffff;padding: 15px;border-radius: 8px;justify-content: center; font-size: 18px;}
.ModalDesginMainPage{background-color: #e0e0e0;border-radius: 7px;padding: 0px;}
.ModalTextMainPage{color: red;font-weight: 500; cursor: pointer;text-decoration: none;}
.ModalTextNameMainPage{color: #000;font-weight: 600;}
.ModalBodyMainPage{height: 680px;}
.spanTextMainPageHead{font-weight: 900;font-size: 45px;}
.spanTextMainPageSubHead{font-weight: 800;font-size: 30px;}
.spanTextModalLines{font-size: 20px;font-weight: 600;}
.SpanTextModalSubLines{padding: 15px;font-size: 20px;font-weight: 800;}
.SpanTextModalSubHeadingLines{padding: 41px;font-size: 20px;font-weight: 600;}

/* THis code is for settingcommunityform second */
.TableTextDesgin{font-weight: 700;border-bottom: solid 1px; margin-right: 15px;}
.TableFormIcon{height: 20px;width: 20px;margin-left: 5px;}
.Normal{display: inline-block;}
.tblCustDesginSecondPage{border-top: #000;border-bottom: #000;}
.DateTextFormSecond{position: absolute;width: 65%;bottom: 20px;text-align: end;}
.DateTextFormSecondIcon{position: absolute;width: 70%;bottom: 20px;text-align: end;}
.hoverit{
    cursor: pointer;
}
.modalbodyGrey{    background-color: #eee;
}
.addgreenHover:hover{
    background-color: #f8fffe;
    cursor: pointer;
}
.Rowselected{
    background-color: #f8fffe;

}