/* For Navigation bar  */
.navigationDesgin{background-color: #48c9b0;padding-left: 10px;padding-right: 10px;}
.NavIcon{height: 20px;width: 20px;}.Normal{display: inline-block;}
.textDesgin{color: #ffffff;}
.navToggleDesgin{color: #ffffff;border: none;}
.navToggleDesgin:focus{border: none;}
.CustIconDesgin{margin-right: 10px;}
.Rowselected{
    background-color: #f8fffe;

}