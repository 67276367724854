/* For Navigation bar  */
.navigationLightDesgin{background-color: #e2e2e2;padding-left: 10px;padding-right: 10px;}
.NavIcon{height: 20px;width: 20px;}.Normal{display: inline-block;}
.textDesginLight{color: #000;font-weight: 600;}
.navToggleDesgin{color: #ffffff;border: none;}
.navToggleDesgin:focus{border: none;}
.textSpanDesgin{color: red;}
.Rowselected{
    background-color: #f8fffe;

}